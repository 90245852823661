




import Vue from 'vue';

export default Vue.extend({
  layout: 'layout-restricted-mobile-view',
  computed: {
    siHaDocumentationShareId() {
      return this.$route.query?.shareId as string | undefined;
    },
  },
  beforeMount() {
    if (this.siHaDocumentationShareId) {
      this.$router.push(`/glaromat/il/siha/mobile/documentation/${this.siHaDocumentationShareId}`);
    }
  },
});
